import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const FieldError = ({error}) => {
  if (!error) return null

  return (
    <div className="field-error">
      {error}
    </div>
  )
}

FieldError.propTypes = {
  error: PropTypes.string,
}

export default FieldError
