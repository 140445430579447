import React from 'react'
import PropTypes from 'prop-types'
import {Progress} from 'antd'

import './index.scss'

const UploadProgress = ({progress}) => {
  return (
    <div className="upload-progress">
      <Progress
        type="circle"
        percent={progress}
        width={80}
      />
    </div>
  )
}

UploadProgress.propTypes = {
  progress: PropTypes.number.isRequired,
}

export default UploadProgress
