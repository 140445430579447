const ESC_KEY = 27

/*
The photo gallery listens to arrow keys for navigation. Right key for next photo,
left key for previous photo. In some cases, for instance with a text field in the photo caption,
we want to disable this feature, so moving the cursor is not changing current photo.

This method can then be added as a callback like this:

<textarea
  onKeyDown={stopArrowPropagation}
  onKeyUp={stopArrowPropagation}
/>
*/

export function stopArrowPropagation(evt) {
  if (evt.keyCode !== ESC_KEY) {
    evt.stopPropagation()
  }
}
