import React from 'react'
import PropTypes from 'prop-types'
import {useLoadScript, GoogleMap, Marker, MarkerClusterer} from '@react-google-maps/api'
import config from 'lib/config'
import './index.scss'

const DEFAULT_CENTER = {lat: 64.811557, lng: 15.820313}
const DEFAULT_ZOOM = 5

function Locations({locations = [], onClickCluster, onClickMarker}) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_TOKEN,
  })
  if (loadError) {
    return (<div>Problem loading map!</div>)
  }
  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      id="map-element"
      zoom={DEFAULT_ZOOM}
      center={DEFAULT_CENTER}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={50}
        onClick={onClickCluster}
      >
        {clusterer =>
          locations.map(loc => (
            <Marker
              key={loc.id}
              position={{lat: loc.latitude, lng: loc.longitude}}
              clusterer={clusterer}
              onClick={() => onClickMarker(loc)}
            />
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  )
}

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
  onClickCluster: PropTypes.func.isRequired,
  onClickMarker: PropTypes.func.isRequired,
}

export default Locations
