import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox'
import * as utils from './utils'

import './index.css'
import noImage from './assets/no-image.png'

class PhotoGallery extends React.Component {
  static propTypes = {
    images: PropTypes.array,
    initialImageIndex: PropTypes.number,
    onCloseRequest: PropTypes.func.isRequired,
    imagePadding: PropTypes.number,
    clickOutsideToClose: PropTypes.bool,
    onRenderCaption: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
    onRenderTitle: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
  }

  static defaultProps = {
    // Add space for toolbar and caption
    imagePadding: 70,

    // Don't close the gallery if "bluring" the photo, like clicking on the overlay
    clickOutsideToClose: false,
  }

  state = {
    photoIndex: this.props.initialImageIndex,
  }

  setPhotoIndex = (photoIndex) => {
    this.setState({photoIndex})
  }

  renderCallback = (callback) => {
    // This render callback supports providing a function that takes the index of the new current asset
    // photo along with useful utils as parameters. It can also take a plain react node in cases where
    // the renderer of this component doesn't care which photo is being rendered.
    // This function is used for the callbacks for the caption and title of the current image.
    if (typeof callback === 'function') {
      return callback(this.state.photoIndex, utils)
    }

    // Is of type Node or not set
    return callback
  }

  render() {
    const {images, ...rest} = this.props
    const photoIndex = this.state.photoIndex
    const prevIndex = photoIndex - 1
    const nextIndex = photoIndex + 1

    function getImage(index) {
      return images[index] === '' ? noImage : images[index]
    }

    return (
      <Lightbox
        {...rest}
        mainSrc={getImage(photoIndex)}
        nextSrc={getImage(nextIndex)}
        prevSrc={getImage(prevIndex)}
        onMovePrevRequest={this.setPhotoIndex.bind(this, prevIndex)}
        onMoveNextRequest={this.setPhotoIndex.bind(this, nextIndex)}
        wrapperClassName="photo-gallery"
        imageCaption={this.renderCallback(this.props.onRenderCaption)}
        imageTitle={this.renderCallback(this.props.onRenderTitle)}
      />
    )
  }
}

export default PhotoGallery
