import React from 'react'
import PropTypes from 'prop-types'
import {notification, message} from 'antd'
import UserContext from 'shared/components/UserContext'
import * as api from 'lib/api'

const ERROR_DURATION = 5 // seconds

function UsingPhotoAPI() {
  return (WrappedComponent) => {
    class PhotoAPILoader extends React.Component {
      state = {
        secrets: null,
      }

      componentDidMount() {
        this.getSecrets()
      }

      getSecrets = () => {
        if (this.state.secrets) return Promise.resolve()
        return api.getSecrets(this.props.user)
          .then(res => this.setState({secrets: res}))
      }

      addPhoto = (photo) => {
        return api.addPhoto(photo, this.props.user)
          .then(res => {
            notification.open({message: 'Bilde opprettet'})
            return res
          })
          .catch(err => {
            message.error('Kunne ikke opprette bilde', ERROR_DURATION)
            throw new Error('Kunne ikke opprette bilde')
          })
      }

      updatePhoto = (photo) => {
        return api.updatePhoto(photo, this.props.user)
          .then(res => {
            notification.open({message: 'Bilde lagret'})
            return res
          })
          .catch(err => {
            message.error('Kunne ikke lagre bilde', ERROR_DURATION)
            throw new Error('Kunne ikke lagre bilde')
          })
      }

      deletePhoto = (photo) => {
        return api.deletePhoto(photo.id, this.props.user)
          .then(() => {
            notification.open({message: 'Bilde slettet'})
          })
          .catch(err => {
            message.error('Kunne ikke slette bilde', ERROR_DURATION)
            throw new Error('Kunne ikke slette bilde')
          })
      }

      uploadPhoto = ({file, onUploadProgress}) => {
        return api.uploadPhoto({
            file,
            onUploadProgress,
            secrets: this.state.secrets.filepicker,
          })
          .catch(err => {
            message.error('Kunne ikke laste opp bilde', ERROR_DURATION)
            throw new Error('Kunne ikke laste opp bilde')
          })
      }

      render() {
        return <WrappedComponent
          {...this.props}
          onUpdatePhoto={this.updatePhoto}
          onAddPhoto={this.addPhoto}
          onDeletePhoto={this.deletePhoto}
          onUploadPhoto={this.uploadPhoto}
        />
      }
    }

    PhotoAPILoader.propTypes = {
      user: PropTypes.object,
    }

    return (props) =>
      <UserContext.Consumer>
        {({user}) =>
          <PhotoAPILoader
            {...props}
            user={user}
          />
      }
      </UserContext.Consumer>
  }
}

export default UsingPhotoAPI
