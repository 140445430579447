import * as React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import UsingPhotoAPI from 'components/UsingPhotoAPI'

import Admin from './Admin'
import Photos from './Photos'
import EditPhoto from './EditPhoto'

import Draft from './Draft'

const Routes = () => {
  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/photos/:scope" component={Photos} />
      <Route path="/edit/:id" component={EditPhoto} />

      <Route path="/draft" render={({history}) => {
        const DraftView = UsingPhotoAPI({loadSecrets: true})(Draft)
        return <DraftView history={history} />
      }}/>

      <Redirect to="/photos/mine" />
    </Switch>
  )
}

export default Routes
