import React from 'react'
import PropTypes from 'prop-types'
import {createImage} from 'lib/imageUtil'
import PageContent from 'components/shared/PageContent'
import EditPhoto from 'components/shared/EditPhotoView'
import UploadZone from './UploadZone'
import Preview from './Preview'

import './index.scss'

class Draft extends React.Component {
  state = {
    images: [],
    currentImage: null,
    beingRemoved: null,
  }

  updateImageProps = (uid, props) => {
    const images = this.state.images
    const image = images.find(i => i.meta.uid === uid)
    if (image) {
      Object.assign(image, props)
      const currentImage = this.state.currentImage
      this.setState({
        images,
        currentImage: currentImage.meta.uid === uid ? image : currentImage
      })
    }
  }

  uploadCompleted = (uid, {url, key = null, container = null}) => {
    this.updateImageProps(uid, {
      uploadProgress: 100,
      url_backend_storage: url,
      backend_storage_key: key,
      backend_storage_container: container,
    })
  }

  uploadProgressUpdated = (uid, uploadProgress) => {
    this.updateImageProps(uid, {uploadProgress})
  }

  onImageAdded = ({meta}) => {
    // this will run in the background
    this.props.onUploadPhoto({
        file: meta.file,
        onUploadProgress: this.uploadProgressUpdated.bind(this, meta.uid),
      })
      .then(this.uploadCompleted.bind(this, meta.uid))

    const image = createImage(meta)
    image.uploadProgress = 0
    const images = [...this.state.images, image]
    const currentImage = this.state.currentImage || image
    this.setState({
      images,
      currentImage,
    })
  }

  onChangeCurrentImage = (uid) => {
    const image = this.state.images.find(i => i.meta.uid === uid)
    if (image) {
      this.setState({currentImage: image})
    }
  }

  onUpdatePhoto = (image) => {
    const images = this.state.images
    const existing = images.find(i => i.meta.uid === image.meta.uid)
    Object.assign(existing, image)
    this.setState({images})
    // if the image has an id, it has previously been inserted, and now needs to be updated
    const promise = image.id ? this.props.onUpdatePhoto : this.props.onAddPhoto

    return promise(existing)
      .then(res => {
        if (!existing.id) {
          // set id of draft photo to that assigned by the backend so that it can be updated rather than re-inserted
          existing.id = res.id
          this.setState({images})
        }
      })
      .catch(() => {}) // already handled
  }

  onRemoveImage = (image) => {
    // if the image has an id, then this draft has already been saved, and needs to be deleted in the backend
    const promise = image.id ? this.props.onDeletePhoto(image) : Promise.resolve()

    this.setState({beingRemoved: image})

    return promise
      .then(() => {
        const images = this.state.images.filter(i => i.meta.uid !== image.meta.uid)

        const state = {images}

        if (this.state.currentImage.meta.uid === image.meta.uid) {
          // need to switch the current image if it was deleted
          state.currentImage = !!images.length ? images[0] : null
        }

        this.setState(state)
      })
      .catch(() => {}) // already handled
      .finally(() => this.setState({beingRemoved: null}))
  }

  render() {
    // EditPhoto should not control the upload props
    const {url_backend_storage, uploadProgress, ...currentImage} = this.state.currentImage || {}

    return (
      <div className="draft">
        <PageContent
          title="Last opp"
        >
          <UploadZone
            onImageAdded={this.onImageAdded}
            onChangeCurrentImage={this.onChangeCurrentImage}
            onRemoveImage={this.onRemoveImage}
            currentImage={this.state.currentImage}
            beingRemoved={this.state.beingRemoved}
            images={this.state.images}
          />
          {!!this.state.images.length && (
            <div className="main-content">
              <Preview image={this.state.currentImage} />
              <EditPhoto
                key={currentImage ? currentImage.meta.uid : null}
                photo={currentImage}
                onUpdatePhoto={this.onUpdatePhoto}
              />
            </div>
          )}
        </PageContent>
      </div>
    )
  }
}

Draft.propTypes = {
  onAddPhoto: PropTypes.func.isRequired,
  onUpdatePhoto: PropTypes.func.isRequired,
  onDeletePhoto: PropTypes.func.isRequired,
  onUploadPhoto: PropTypes.func.isRequired,
}

export default Draft
