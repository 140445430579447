import React from 'react'
import PropTypes from 'prop-types'

import {getOrientation, formatFileSize} from 'lib/imageUtil'

import './index.scss'

const Preview = ({image}) => {
  const imageMeta = (image) => {
    if (!image) return null

    return (
      <React.Fragment>
        <span>{getOrientation(image)}</span>
        <span>{formatFileSize(image.file_size)}</span>
        <span>{image.width}x{image.height}px</span>
      </React.Fragment>
    )
  }

  return (
    <div className="upload-preview">
      <div className="image-container">
        {image ? <img src={image.meta.imageData} alt="Forhåndsvisning" /> : null}
      </div>
      <div className="meta">{imageMeta(image)}</div>
    </div>
  )
}

Preview.propTypes = {
  image: PropTypes.object,
}

export default Preview
