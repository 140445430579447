import React from 'react'
import PropTypes from 'prop-types'
import Query from 'components/shared/Query'
import Mutation from 'components/shared/Mutation'
import PageContent from 'components/shared/PageContent'

import ReviewPhoto from './ReviewPhoto'

import './index.scss'

const Qa = ({match}) => {
  return (
    <div className="photo-qa">
      <Query
        inlineLoading
        inlineError
        path="photos"
        params={{
          by: 'all',
          page: 1,
          per_page: 40,
          status: match.params.scope,
        }}
      >
        {({data, fetchData}) => {
          const photos = data.photos
          const currentPhoto = photos[0]

          const imagesWaitingString = () => {
            const waiting = photos.length
            const imageString = waiting === 1 ? 'bilde' : 'bilder'
            return `${waiting} ${imageString} venter`
          }

          return (
            <PageContent
              title={
                <div>
                  Kvalitetssikring av bilder <span className="remaining">({imagesWaitingString()})</span>
                </div>
              }
            >
              {!!currentPhoto && (
                <Mutation
                  method="PUT"
                  path={`photos/${currentPhoto.id}`}
                >
                  {(updatePhoto) => (
                    <ReviewPhoto
                      photo={currentPhoto}
                      onReviewPhoto={({status, rejectComment}) =>
                        updatePhoto({
                          variables: {photo: {status, reject_reason: rejectComment}},
                        }).then(() => fetchData())
                      }
                    />
                  )}
                </Mutation>
              )}

              {!currentPhoto && (
                <div>Ingen bilder i køen.</div>
              )}
            </PageContent>
          )
        }}
      </Query>

    </div>
  )
}

Qa.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Qa
