import React from 'react'
import {Link} from 'react-router-dom'
import {Layout, Menu} from 'antd'
import {withRouter} from 'react-router'

import UserContext from 'shared/components/UserContext'

import './index.scss'

class AppHeader extends React.Component {
  get currentPage() {
    const location = this.props.location.pathname

    if (location.includes('/photos/mine')) return 'mine'
    if (location.includes('/draft')) return 'mine'
    if (location.includes('/photos/all')) return 'all'
    if (location.includes('/admin/locations')) return 'admin-locations'
    return 'admin-dashboard'
  }

  render() {
    return (
      <UserContext.Consumer>
        {({user, signOut}) =>
          <Layout.Header className="header">
            <div className="logo">
              EFKT Bildebank
            </div>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[this.currentPage]}
              className="pages"
            >
              <Menu.Item key="mine"><Link to="/photos/mine">Mine bilder</Link></Menu.Item>
              <Menu.Item key="all"><Link to="/photos/all">Alle bilder</Link></Menu.Item>
              {!!(user && user.isAdmin) && (
                <Menu.SubMenu title={<Link to="/admin">Administrator</Link>}>
                  <Menu.Item key="admin-dashboard"><Link to="/admin">QA-dashboard</Link></Menu.Item>
                  <Menu.Item key="admin-locations"><Link to="/admin/locations">Kart over lokasjoner</Link></Menu.Item>
                </Menu.SubMenu>
              )}
            </Menu>
            <div className="user-menu">
              <div>{user.name}</div>
              <div><span className="logout-link" onClick={signOut}>Logg ut</span></div>
            </div>
          </Layout.Header>
      }
      </UserContext.Consumer>
    )
  }
}

export default withRouter(AppHeader)
