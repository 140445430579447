import localStorage from './localStorage'

export function getSession(key) {
  return localStorage.getItem(key)
}

export function setSession(key, token) {
  return localStorage.setItem(key, token)
}

export function clearSession(key) {
  return localStorage.removeItem(key)
}
