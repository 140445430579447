import React from 'react'
import PropTypes from 'prop-types'
import {Upload} from 'antd'
import {PlusOutlined} from '@ant-design/icons'

import {parseImageMeta, getImageData} from 'lib/imageUtil'

import UploadedImages from './UploadedImages'

import './index.scss'

class UploadZone extends React.Component {
  addFile = (file) => {
    Promise.all([parseImageMeta(file), getImageData(file)])
      .then(([meta, fileData]) => {
        const {imageData, width, height} = fileData
        this.props.onImageAdded({
          meta: {
            ...meta,
            file,
            width,
            height,
            imageData,
            uid: file.uid,
          },
        })
      })

    return false // return false to skip the built-in upload function
  }

  // this creates an antd <Upload>-friendly list of uploads
  uploadList = () => this.props.images.map(i => ({uid: i.meta.uid, url: i.meta.imageData}))

  render() {
    return (
      <div className="upload-grid">
        <UploadedImages
          images={this.props.images}
          currentImage={this.props.currentImage}
          beingRemoved={this.props.beingRemoved}
          onImageClicked={this.props.onChangeCurrentImage}
          onRemoveDraft={this.props.onRemoveImage}
        />
        <Upload
          accept="image/jpeg"
          listType="picture-card"
          fileList={this.uploadList()}
          showUploadList={false}
          beforeUpload={this.addFile}
        >
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Last opp</div>
          </div>
        </Upload>
      </div>
    )
  }
}

UploadZone.propTypes = {
  images: PropTypes.array.isRequired,
  currentImage: PropTypes.object,
  beingRemoved: PropTypes.object,
  onImageAdded: PropTypes.func.isRequired,
  onChangeCurrentImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
}

export default UploadZone
