import React from 'react'
import PropTypes from 'prop-types'
import {Tag, Input, Tooltip} from 'antd'

import './index.scss'

const LONG_TAG_THRESHOLD = 20

class ImageTags extends React.Component {
  state = {
    tags: this.props.tags || [],
    inputValue: '',
  }

  tagsUpdated = (tags) => {
    this.props.onTagsUpdated(tags)
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter(tag => tag !== removedTag)
    this.tagsUpdated(tags)
    this.setState({tags})
  }

  showInput = () => {
    this.input.focus()
  }

  handleInputChange = (e) => {
    this.setState({inputValue: e.target.value})
  }

  handleInputConfirm = () => {
    const {inputValue, tags} = this.state
    if (inputValue && !tags.includes(inputValue)) {
      tags.push(inputValue)
    }
    this.tagsUpdated(tags)
    this.setState({
      tags,
      inputValue: '',
    })
  }

  saveInputRef = input => this.input = input

  render() {
    const {tags, inputValue} = this.state
    return (
      <div className={`input-element image-tags ${this.props.editable ? 'editable' : ''}`}>
        {tags.map((tag) => {
          const isLongTag = tag.length > LONG_TAG_THRESHOLD
          const tagElem = (
            <Tag
              key={tag}
              closable={this.props.editable}
              onClose={() => this.handleClose(tag)}
            >
              {isLongTag ? `${tag.slice(0, LONG_TAG_THRESHOLD)}...` : tag}
            </Tag>
          )
          return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem
        })}
        {this.props.editable && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
      </div>
    )
  }
}

ImageTags.propTypes = {
  tags: PropTypes.array,
  onTagsUpdated: PropTypes.func,
  editable: PropTypes.bool,
}

export default ImageTags
