import React from 'react'
import PropTypes from 'prop-types'
import {Table} from 'antd'

import './index.scss'

const Users = ({users = []}) => {
  const columns = [{
    title: 'Navn',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: 'Antall bilder',
    dataIndex: 'photos_count',
    key: 'photos_count',
    sorter: (a, b) => a.photos_count - b.photos_count,
    defaultSortOrder: 'descend'
  }, {
    title: 'Antall solgte',
    dataIndex: 'photos_purchases_count',
    key: 'photos_purchases_count',
    sorter: (a, b) => a.photos_purchases_count - b.photos_purchases_count,
  }]

  return (
    <div>
      <Table
        rowKey={row => row.id}
        dataSource={users}
        columns={columns}
      />
    </div>
  )
}

Users.propTypes = {
  users: PropTypes.array,
}

export default Users
