import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import config from 'lib/config'

const getCode = (location) => {
  const params = qs.parse(location.search, {ignoreQueryPrefix: true})
  return params.code
}

const Login = (props) => {
  const code = getCode(props.location)
  if (code) return (
    <div>
      Logger inn...
    </div>
  )

  return (
    <div>
      <a href={config.OAUTH.IWEB_AUTHORIZE_URL}>Logg inn med din iWeb-bruker</a>
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Login
