import React, {useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Card, Modal, Tooltip} from 'antd'
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons'

import config from 'lib/config'
import UserContext from 'shared/components/UserContext'

import './index.scss'

const PhotoWithUser = ({user, photo, onDelete, onClick}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const canEdit = user.isAdmin || photo.uploaded_by_id === user.id
  const confirmDelete = () => setShowDeleteConfirmation(true)
  const cancelDelete = () => setShowDeleteConfirmation(false)

  let statusTip = null
  switch (photo.status) {
    case 'existing':
    case 'approved':
      statusTip = (
        <Tooltip title="Bildet er godkjent">
          <CheckCircleOutlined className={`photo-status ${photo.status}`} />
        </Tooltip>
      )
      break

    case 'pending':
      statusTip = (
        <Tooltip title="Bildet avventer QA">
          <PauseCircleOutlined className="photo-status pending" />
        </Tooltip>
      )
      break

    case 'rejected':
      statusTip = (
        <Tooltip title="Bildet er avvist">
          <StopOutlined className="photo-status rejected" />
        </Tooltip>
      )
      break

    default:
  }

  const actions = [
    <Link to={`/edit/${photo.id}`}><EditOutlined /></Link>,
  ]

  if (canEdit) {
    actions.push(<div onClick={confirmDelete}><DeleteOutlined className="delete-photo" /></div>)

    if (statusTip) {
      actions.push(statusTip)
    }
  }

  return (
    <div className="photo">
      <Card
        hoverable
        cover={<div className="image-container"><img src={photo.url_monitor} alt={photo.name} onClick={onClick}/></div>}
        actions={canEdit ? actions : null}
        className="image-card"
      >
        <Card.Meta
          title={photo.name}
          description={(
            <div>
              <div>{moment(photo.taken_on).format(config.OUTPUT_DATE_FORMAT)}</div>
              <div className="purchases-count">Kjøpt {photo.purchases_count} ganger</div>
            </div>
          )}
        />
      </Card>

      {showDeleteConfirmation && (
        <Modal
          visible={true}
          title="Er du sikker på at du vil slette dette bildet?"
          onOk={onDelete}
          onCancel={cancelDelete}
          okText="OK"
          cancelText="Avbryt"
          closable={false}
          maskClosable={false}
        />
      )}
    </div>
  )
}

PhotoWithUser.propTypes = {
  user: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

const Photo = (props) => {
  return (
    <UserContext.Consumer>
      {({user}) => <PhotoWithUser user={user} {...props} />}
    </UserContext.Consumer>
  )
}

Photo.Wrapper = ({children}) => {
  return (
    <div className="photo-wrapper">
      {children}
    </div>
  )
}

export default Photo
