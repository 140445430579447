import React from 'react'
import PropTypes from 'prop-types'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import {getSession, setSession, clearSession} from 'shared/lib/session'
import UserContext from 'shared/components/UserContext'

import Login from './Login'

class AppWithAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      didSignOut: false,
      session: JSON.parse(getSession(props.options.sessionKey)),
    }
  }

  signout = () => {
    clearSession(this.props.options.sessionKey)
    this.setState({session: null, didSignOut: true})
  }

  signin = (session) => {
    setSession(this.props.options.sessionKey, JSON.stringify(session))
    this.setState({session})
  }

  defaultRoute = (props) => {
    const {session, didSignOut} = this.state

    if (session) {
      return (
        <UserContext.Provider value={{
          signOut: this.signout,
          user: session,
        }}>
          {this.props.children}
        </UserContext.Provider>
      )
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: props.location,
            didSignOut: didSignOut,
          },
        }}
      />
    )
  }

  render() {
    const {components, options} = this.props

    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/login"
            render={(props) =>
              this.state.session ? (
                <Redirect to="/" />
              ) : (
                <Login
                  {...props}
                  sessionKey={options.sessionKey}
                  onSignin={this.signin}
                  Component={components.Login}
                />
          )}/>
          <Route render={this.defaultRoute} />
        </Switch>
      </BrowserRouter>
    )
  }
}

AppWithAuth.propTypes = {
  options: PropTypes.object,
  components: PropTypes.object,
  children: PropTypes.node,
}

export default AppWithAuth
