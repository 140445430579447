import React from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash.pick'
import Query from 'components/shared/Query'
import Mutation from 'components/shared/Mutation'
import PageContent from 'components/shared/PageContent'
import EditPhotoView from 'components/shared/EditPhotoView'

const getPhotoData = (photoData) => {
  const photo = {
    ...pick(photoData, [
      'name',
      'file_size',
      'url_backend_storage',
      'backend_storage_key',
      'backend_storage_container',
      'season',
      'taken_on',
      'longitude',
      'latitude',
      'description',
      'status',
      'reject_reason',
      'width',
      'height'
    ]),
    content_type: photoData.type
  }

  if (photoData.tag_list) {
    photo.tag_list = photoData.tag_list.join(',')
  }

  return photo
}

const EditPhoto = ({match, history}) => {
  const photoId = match.params.id

  return (
    <Mutation
      method="PUT"
      path={`photos/${photoId}`}
    >
      {(updatePhoto) => (
        <Query
          inlineLoading
          inlineError
          path={`photos/${photoId}`}
        >
          {({data}) => (
            <PageContent title="Rediger bildeinformasjon">
              <EditPhotoView
                photo={data.photo}
                onCancel={() => history.goBack()}
                onUpdatePhoto={async (photo) => {
                  await updatePhoto({
                    variables: {
                      photo: getPhotoData(photo),
                    }
                  })
                  history.goBack()
                }}
              />
            </PageContent>
          )}
        </Query>
      )}
    </Mutation>
  )
}

EditPhoto.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default EditPhoto
