import React from 'react'
import ReactDOM from 'react-dom'
import PrivateRoutes from 'components/PrivateRoutes'
import Login from 'components/Login'
import AppWithAuth from 'components/shared/AppWithAuth'
import AppHeader from 'components/shared/AppHeader'

import './index.scss'

ReactDOM.render(
  <AppWithAuth
    options={{
      sessionKey: 'inviso-bildebank-session',
    }}
    components={{
      Login,
    }}
  >
    <AppHeader />
    <div className="app">
      <PrivateRoutes />
    </div>
  </AppWithAuth>
  , document.getElementById('root'))
