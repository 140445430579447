import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Pagination, notification} from 'antd'
import Query from 'components/shared/Query'
import Mutation from 'components/shared/Mutation'
import Photo from 'components/shared/Photo'
import PhotoGallery from 'shared/components/PhotoGallery'

import 'react-image-lightbox/style.css'
import './index.scss'

const PhotosWithGallery = ({
  page,
  setPage,
  perPage = 40,
  params = {},
}) => {
  const [photoPreviewIndex, setPhotoPreviewIndex] = useState(null)

  return (
    <div className="photos-with-gallery">
      <Query
        inlineLoading
        inlineError
        path="photos"
        params={{
          page,
          per_page: perPage,
          ...params,
        }}
      >
        {({data, fetchData}) => (
          <>
            <Photo.Wrapper>
              {data.photos.map((photo, i) => (
                <Mutation key={photo.id} path={`photos/${photo.id}`} method="DELETE">
                  {(deletePhoto) => (
                    <Photo
                      key={photo.id}
                      photo={photo}
                      onClick={() => setPhotoPreviewIndex(i)}
                      onDelete={() => {
                        deletePhoto()
                          .then(() => {
                            notification.success({message: 'Bilde slettet'})
                            fetchData()
                          })
                          .catch(err => {
                            notification.error({message: 'Kunne ikke slette bilde'})
                          })
                      }}
                    />
                  )}
                </Mutation>
              ))}
            </Photo.Wrapper>

            <div className="photos-pagination">
              <Pagination
                pageSize={perPage}
                total={data.meta.total}
                current={page}
                onChange={setPage}
              />
            </div>

            {photoPreviewIndex !== null &&
              <PhotoGallery
                images={data.photos.map(p => p.url_monitor)}
                initialImageIndex={photoPreviewIndex}
                onCloseRequest={() => setPhotoPreviewIndex(null)}
              />
            }
          </>
        )}
      </Query>
    </div>
  )
}

PhotosWithGallery.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  perPage: PropTypes.number,
  params: PropTypes.object,
}

export default PhotosWithGallery
