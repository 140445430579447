import * as React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import UserContext from 'shared/components/UserContext'
import Dashboard from './Dashboard'
import Locations from './Locations'
import Qa from './Qa'

import './index.scss'

const Admin = ({match}) => {
  return (
    <UserContext.Consumer>
      {({user}) => {
        if (!user.isAdmin) {
          return <Redirect to="/" />
        }

        return (
          <Switch>
            <Route path={`${match.path}/locations`} component={Locations} />
            <Route path={`${match.path}/qa/:scope`} component={Qa} />
            <Route path={match.path} component={Dashboard} />
          </Switch>
        )
      }}
    </UserContext.Consumer>
  )
}

export default Admin
