import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Button, Radio, Input, DatePicker} from 'antd'
import config from 'lib/config'
import seasons from 'lib/seasons'
import ImageTags from 'components/shared/ImageTags'
import FieldContainer from 'components/shared/FieldContainer'
import FieldError from 'components/shared/FieldError'
import PhotoLocationMap from './PhotoLocationMap'
import LocationSearch from './LocationSearch'

import './index.scss'

class EditPhoto extends React.Component {
  state = {
    valid: false,
    errors: {},
    saving: false,
    photo: this.props.photo,
    mapPopupClosed: false,
  }

  get photo() {
    const photo = {...this.state.photo}
    if (this.props.photo) photo.id = this.props.photo.id
    return photo
  }

  validateField(field, value) {
    const required = ['name', 'description', 'tag_list']
    if (required.includes(field) && (!value || !value.length)) return 'Må fylles ut'

    if (field === 'latitude' && !value) return 'Må plasseres i kart'
  }

  validate(fields = this.state) {
    const errors = this.state.errors
    Object.entries(fields).forEach(([field, value]) => {
      const error = this.validateField(field, value)
      if (error) errors[field] = error
      else delete errors[field]
    })

    const valid = !Object.keys(errors).length
    this.setState({
      errors,
      valid,
    })

    return valid
  }

  update(props, validate = true) {
    const state = {
      photo: {
        ...this.state.photo,
        ...props,
      }
    }

    if (validate) this.validate(props)
    else state.errors = {}

    this.setState(state)
  }

  nameChanged = (evt) => {
    this.update({name: evt.target.value})
  }

  descriptionChanged = (evt) => {
    this.update({description: evt.target.value})
  }

  seasonChanged = (evt) => {
    this.update({season: evt.target.value})
  }

  onTagsUpdated = (tags) => {
    this.update({tag_list: tags})
  }

  dateChanged = (evt) => {
    this.update({taken_on: evt ? evt.format(config.IMAGE_DATE_FORMAT) : null})
  }

  locationChanged = ({lat, lng}) => {
    this.update({latitude: lat, longitude: lng})
  }

  onUpdatePhoto = () => {
    if (this.validate()) {
      this.setState({saving: true})
      this.props.onUpdatePhoto(this.photo)
        .finally(() => this.setState({saving: false}))
    }
  }

  get shouldShowMapPopup() {
    return !this.state.mapPopupClosed
  }

  closeMapPopup() {
    this.setState({mapPopupClosed: true})
  }

  render() {
    const photo = this.photo || {}

    const location = (photo.latitude && photo.longitude) ? {lat: photo.latitude, lng: photo.longitude} : null
    return (
      <div className="edit-photo">
        <div className="top-section">
          <div className="image-preview">
            <img src={photo.url_monitor} alt="" />
          </div>
          <div className="info-form">
            <h2>* Alle felter er påkrevde</h2>
            <div className="owner-name">Fotograf: {photo.owner_name}</div>
            <FieldContainer error={this.state.errors.name}>
              <Input placeholder="Bildenavn" value={photo.name} onChange={this.nameChanged} />
            </FieldContainer>
            <div className="photo-date">
              <span>Når ble bildet tatt?</span>
              <DatePicker
                value={moment(photo.taken_on)}
                format={config.OUTPUT_DATE_FORMAT}
                onChange={this.dateChanged}
                allowClear={false}
              />
            </div>
            <div className="season">
              <span>Årstid</span>
              <Radio.Group value={photo.season} onChange={this.seasonChanged}>
              {Object.entries(seasons).map(([season, seasonName]) => (
                <Radio key={season} value={season}>{seasonName}</Radio>
              ))}
              </Radio.Group>
            </div>
            <FieldContainer error={this.state.errors.tag_list}>
              Merkelapper
              <ImageTags
                tags={photo.tag_list}
                onTagsUpdated={this.onTagsUpdated}
                editable={true}
              />
            </FieldContainer>
            <FieldContainer error={this.state.errors.description}>
              Beskrivelse
              <Input.TextArea
                rows={2}
                value={photo.description}
                onChange={this.descriptionChanged}
              />
            </FieldContainer>
          </div>
        </div>
        <div className="map-container">
          <div className="map">
            <PhotoLocationMap
              location={location}
              locationChanged={this.locationChanged}
            />
            {this.shouldShowMapPopup && (
              <div className="overlay">
                <div
                  className="popup"
                >
                  <h3>Plasser motivet på kartet</h3>
                  <p>
                    Flytt markøren til området bilde ble tatt i.
                  </p>
                  <div className="actions">
                    <img src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png" className="marker" alt="" />
                    <Button
                      type="primary"
                      onClick={this.closeMapPopup.bind(this)}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <LocationSearch
            locationSelected={this.locationChanged}
          />
          <FieldError error={this.state.errors.latitude} />
        </div>
        <div className="action-bar">
          {this.props.onCancel && (
            <Button
              onClick={this.props.onCancel}
            >
              Gå tilbake
            </Button>
          )}

          <Button
            type="primary"
            onClick={this.onUpdatePhoto}
            disabled={this.state.saving}
          >
            Lagre
          </Button>
        </div>
      </div>
    )
  }
}

EditPhoto.propTypes = {
  photo: PropTypes.object,
  onUpdatePhoto: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

export default EditPhoto
