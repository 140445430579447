import React from 'react'
import PropTypes from 'prop-types'
import {DeleteOutlined} from '@ant-design/icons'

import UploadProgress from './UploadProgress'

import './index.scss'

const UploadedImages = ({images, currentImage, beingRemoved, onImageClicked, onRemoveDraft}) => {
  const removeDraft = (evt, image) => {
    evt.stopPropagation()
    onRemoveDraft(image)
  }

  return (
    <React.Fragment>
      {
        images.map((image) => {
          const itemClasses = ['upload-grid-item']
          if (!!currentImage && currentImage.meta.uid === image.meta.uid) itemClasses.push('current')

          return (
            <div
              key={image.meta.uid}
              className={itemClasses.join(' ')}
              onClick={() => onImageClicked(image.meta.uid)}
            >
              <img src={image.meta.imageData} alt="Forhåndsvisning" />
              <div className="actions" onClick={(evt) => removeDraft(evt, image)}><DeleteOutlined /></div>
              {(beingRemoved && beingRemoved.meta.uid === image.meta.uid) && (<div className="being-removed"></div>)}
              {(image.uploadProgress < 100) && <UploadProgress progress={image.uploadProgress} />}
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

UploadedImages.propTypes = {
  images: PropTypes.array.isRequired,
  currentImage: PropTypes.object,
  beingRemoved: PropTypes.object,
  onImageClicked: PropTypes.func.isRequired,
  onRemoveDraft: PropTypes.func.isRequired,
}

export default UploadedImages
