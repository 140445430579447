import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import {signIn} from 'lib/api'

const getCode = (location) => {
  const params = qs.parse(location.search, {ignoreQueryPrefix: true})
  return params.code
}

class Login extends React.Component {
  state = {
    error: null,
  }

  async componentDidMount() {
    // Check if sign-on token is provided
    const code = getCode(this.props.location)
    if (code) {
      const didSignIn = await this.performAuthRequest(code)
      if (!didSignIn) {
        this.setState({
          error: 'Kunne ikke logge inn.',
        })
      }
    }
  }

  handleAuthResponse = res => {
    if (res) {
      this.props.onSignin(res)
    }
    else {
      this.setState({error: 'Kunne ikke logge inn.'})
    }

    return res
  }

  performAuthRequest = code => {
    return signIn(code)
      .then(this.handleAuthResponse)
      .catch(err => {
        console.log('err', err)
        return this.setState({
          error: 'Kunne ikke logge inn.'
        })
      })
  }

  render() {
    const LoginComponent = this.props.Component
    return (
      <LoginComponent
        error={this.state.error}
        location={this.props.location}
      />
    )
  }
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  onSignin: PropTypes.func.isRequired,
  sessionKey: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
}

export default Login
