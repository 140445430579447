import React from 'react'
import PropTypes from 'prop-types'
import FieldError from 'components/shared/FieldError'

import './index.scss'

const FieldContainer = ({error, children}) => {
  return (
    <div className={error ? 'has-error' : ''}>
      {children}
      <FieldError error={error} />
    </div>
  )
}

FieldContainer.propTypes = {
  error: PropTypes.string,
  children: PropTypes.node,
}

export default FieldContainer
