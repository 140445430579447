import React from 'react'
import PropTypes from 'prop-types'
import * as api from 'lib/api'
import UserContext from 'shared/components/UserContext'

function compareProps(a, b) {
  return a.path === b.path && JSON.stringify(a.params) === JSON.stringify(b.params)
}

class QueryWithUser extends React.Component {
  state = {
    loading: true,
    data: {},
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (!compareProps(this.props, prevProps)) {
      this.fetchData()
    }
  }

  fetchData = () => {
    this.setState({loading: true})
    return api.get({
      path: this.props.path,
      params: this.props.params,
      user: this.props.user,
    }).then(data => {
      this.setState({data, loading: false, error: null})
    }).catch(error => {
      this.setState({data: {}, loading: false, error})
    })
  }

  render() {
    if (this.props.inlineLoading && this.state.loading) {
      return (
        <div className="loading">Henter innhold...</div>
      )
    }

    if (this.props.inlineError && this.state.error) {
      console.error(this.state.error)
      return (
        <div className="error">
          En feil har oppstått og innholdet her kan ikke lastes inn.
          Last siden på nytt og førsøk på nytt. Vennligst kontakt EFKT om
          problemet vedvarer.
        </div>
      )
    }

    const data = {...this.state, fetchData: this.fetchData}

    return this.props.children(data)
  }
}

QueryWithUser.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  params: PropTypes.object,
  inlineLoading: PropTypes.bool,
  inlineError: PropTypes.bool,
}

const Query = (props) => {
  return (
    <UserContext.Consumer>
      {({user}) => <QueryWithUser user={user} {...props} />}
    </UserContext.Consumer>
  )
}

export default Query
