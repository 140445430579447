import pick from 'lodash.pick'
import qs from 'qs'
import config from 'lib/config'
import * as filestack from 'filestack-js'

const callAPI = ({url, user, method = 'GET', body}) => {
  const options = {
    method,
    headers: {}
  }

  if (user) {
    options.headers['auth-email'] = user.auth_email
    options.headers['auth-token'] = user.auth_token
  }

  if (['POST', 'PUT', 'PATCH'].includes(method)) {
    options.headers.Accept = 'application/json'
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(body)
  }

  return fetch(url, options)
}

export const get = ({user, path, params = {}}) => {
  const query = qs.stringify(params)
  let url = `${config.BACKEND_API}/${path}`
  if (query) url += `?${query}`
  return callAPI({url, user}).then(res => res.json())
}

export const update = ({user, path, body = {}}) => {
  const url = `${config.BACKEND_API}/${path}`
  return callAPI({url, user, body, method: 'PUT'})
}

export const destroy = ({user, path}) => {
  const url = `${config.BACKEND_API}/${path}`
  return callAPI({url, user, method: 'DELETE'})
}

export const signIn = (code) => {
  const url = config.OAUTH.BACKEND_AUTH_URL

  return callAPI({url, method: 'POST', body: {code}})
    .then(res => {
      if (res.status >= 400) {
        throw new Error('Kunne ikke logge inn')
      }
      return res.json()
    })
}

const getPhotoData = (photoData, mode) => {
  const photo = {
    ...pick(photoData, [
      'name',
      'file_size',
      'url_backend_storage',
      'backend_storage_key',
      'backend_storage_container',
      'season',
      'taken_on',
      'longitude',
      'latitude',
      'description',
      'status',
      'reject_reason',
      'width',
      'height'
    ]),
    content_type: photoData.type
  }
  if (photoData.tag_list) {
    photo.tag_list = photoData.tag_list.join(',')
  }

  if (mode === 'update') {
    photo.id = photoData.id
  }

  return photo
}

export const addPhoto = (photoData, user) => {
  const url = `${config.BACKEND_API}/photos`

  const photo = getPhotoData(photoData, 'add')

  return callAPI({url, user, method: 'POST', body: {photo}})
    .then(res => {
      if (res.status === 201) {
        return res.json()
      }
      throw new Error(res.errors)
    })
    .then(res => {
      return res.photo
    })
}

export const updatePhoto = (photoData, user) => {
  const url = `${config.BACKEND_API}/photos/${photoData.id}`

  const photo = getPhotoData(photoData, 'update')

  return callAPI({url, user, method: 'PUT', body: {photo}})
    .then(res => {
      if (res.status === 204) {
        return true
      }
      throw new Error(res.errors)
    })
}

export const deletePhoto = (id, user) => {
  const url = `${config.BACKEND_API}/photos/${id}`

  return callAPI({url, user, method: 'DELETE'})
    .then(res => {
      if (res.status === 204) {
        return true
      }
      throw new Error(res.errors)
    })
}

export const getSecrets = (user) => {
  const url = `${config.BACKEND_API}/secrets`

  return callAPI({url, user})
    .then(res => {
      if (res.status === 200) {
        return res.json()
      }
      throw new Error(res.errors)
    })
}

export const uploadPhoto = ({file, onUploadProgress, secrets}) => {
  const {key, policy, signature} = secrets
  const client = filestack.init(key)

  const onProgress = (evt) => onUploadProgress(evt.totalPercent)

  return client.upload(file, { onProgress }, {}, {}, {policy, signature})
    .then(res => {
      if (res.status === 'Stored') {
        return res
      }
      throw new Error('Failed to upload to FileStack')
    })
}
