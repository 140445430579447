import React, {useState} from 'react'
import PropTypes from 'prop-types'
import * as api from 'lib/api'
import UserContext from 'shared/components/UserContext'

const MutationWithUser = ({user, children, path, method}) => {
  const [loading, setLoading] = useState(false)

  const wrapApiPromise = (promise) => {
    setLoading(true)
    return promise.then((res) => {
      setLoading(false)
      return res
    }).catch((err) => {
      setLoading(false)
      throw err
    })
  }

  const mutator = ({variables = {}} = {}) => {
    switch (method) {
      case 'DELETE':
        return wrapApiPromise(api.destroy({path, user}))
      case 'PUT':
        return wrapApiPromise(api.update({path, body: variables, user}))
      default:
        throw new Error('Unknown action in Mutation')
    }
  }

  return children(mutator, {loading})
}

MutationWithUser.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
}

const Mutation = (props) => {
  return (
    <UserContext.Consumer>
      {({user}) => <MutationWithUser user={user} {...props} />}
    </UserContext.Consumer>
  )
}

export default Mutation
