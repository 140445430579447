import React from 'react'
import PropTypes from 'prop-types'
import {Card, Button, Modal, Input} from 'antd'
import moment from 'moment'

import seasons from 'lib/seasons'

import ImageTags from 'components/shared/ImageTags'
import PhotoGallery from 'shared/components/PhotoGallery'

import 'react-image-lightbox/style.css'
import './index.scss'

class PhotoQA extends React.Component {
  state = {
    isFullscreen: false,
    isRejectingImage: false,
    rejectComment: '',
    saving: null,
  }

  showFullscreen() {
    this.setState({isFullscreen: true})
  }

  closeFullscreen() {
    this.setState({isFullscreen: false})
  }

  clearRejection() {
    this.setState({isRejectingImage: false, rejectComment: ''})
  }

  rejectImage() {
    this.setState({isRejectingImage: true})
  }

  submitRejection() {
    this.onReviewImage('rejected')
    this.clearRejection()
  }

  approveImage() {
    this.onReviewImage('approved')
  }

  onReviewImage(status) {
    return this.props.onReviewPhoto({
      status,
      rejectComment: this.state.rejectComment
    })
  }

  updateRejectComment(rejectComment) {
    this.setState({rejectComment})
  }

  render() {
    const photo = this.props.photo

    const details = [
      {
        label: "Fotograf",
        value: photo.owner_name,
      },
      {
        label: 'Tittel',
        value: photo.name,
      },
      {
        label: 'Årstid',
        value: seasons[photo.season],
      },
      {
        label: 'Dato',
        value: moment(photo.taken_on).format('DD.MM.YYYY'),
      },
      {
        label: 'Beskrivelse',
        value: photo.description,
      },
      {
        label: 'Merkelapper',
        value: (
          <ImageTags
            tags={photo.tag_list}
            editable={false}
          />
        )
      },
    ]

    if (photo.reject_reason) {
      details.push({label: 'Tidligere QA', value: photo.reject_reason})
    }

    const saving = this.props.saving

    return (
      <div className="photo-card">
        <Card
          cover={(
            <div className="photo" onClick={this.showFullscreen.bind(this)}>
              <img src={photo.url_monitor} alt="" />
            </div>
          )}
          actions={[
            <Button
              type="danger"
              onClick={this.rejectImage.bind(this)}
              className="reject"
              disabled={!!saving}
              loading={saving === 'rejected'}
            >
              Avvis
            </Button>,
            <Button
              type="primary"
              onClick={this.approveImage.bind(this)}
              className="approve"
              disabled={!!saving}
              loading={saving === 'approved'}
            >
              Godkjenn
            </Button>
          ]}
        >
          <Card.Meta
            title="Detaljer"
            description={(
              <div className="details">
                {details.map((detail, i) => (
                  <div key={i}><div className="label">{detail.label}:</div> {detail.value}</div>
                ))}
              </div>
            )}
          />
        </Card>
        <Modal
          title="Gi en årsak til at bildet avvises"
          visible={this.state.isRejectingImage}
          onOk={this.submitRejection.bind(this)}
          onCancel={this.clearRejection.bind(this)}
          closable={false}
          maskClosable={false}
          okText="Send"
          cancelText="Avbryt"
          rows={6}
        >
          <Input.TextArea
            placeholder="Bildet ble avvist fordi..."
            value={this.state.rejectComment}
            onChange={e => this.updateRejectComment(e.target.value)}
          />
        </Modal>
        {this.state.isFullscreen && (
          <PhotoGallery
            images={[photo.url_monitor]}
            initialImageIndex={0}
            onCloseRequest={this.closeFullscreen.bind(this)}
          />
        )}
      </div>
    )
  }
}

PhotoQA.propTypes = {
  photo: PropTypes.object.isRequired,
  onReviewPhoto: PropTypes.func.isRequired,
  saving: PropTypes.string,
}

export default PhotoQA
