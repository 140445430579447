import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'antd'
import {Link} from 'react-router-dom'
import PhotosWithGallery from 'components/shared/PhotosWithGallery'
import PageContent from 'components/shared/PageContent'

import './index.scss'

const Photos = ({match, perPage = 40}) => {
  const [page, setPage] = useState(1)

  const scope = match.params.scope
  const isMine = scope === 'mine'

  return (
    <div className="page photos">
      <PageContent title={isMine ? 'Mine bilder' : 'Alle bilder'}>
        {isMine && (
          <Link to="/draft" className="upload-link">
            <Button type="primary">Last opp bilder</Button>
          </Link>
        )}

        <PhotosWithGallery
          perPage={perPage}
          page={page}
          setPage={p => setPage(p)}
          params={{
            by: scope,
          }}
        />
      </PageContent>
    </div>
  )
}

Photos.propTypes = {
  match: PropTypes.object.isRequired,
  perPage: PropTypes.number,
}

export default Photos
