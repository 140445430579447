import React from 'react'
import PropTypes from 'prop-types'
import {Divider} from 'antd'

import './index.scss'

const PageContent = ({title, children}) => {
  return (
    <div className="page-content">
      <h1>{title}</h1>
      <Divider />
      {children}
    </div>
  )
}

PageContent.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  children: PropTypes.node,
}

export default PageContent
