export const parseDate = (dateString) => {
  const parts = dateString.split(/\D/)
  return new Date(parts[0], parts[1]-1, parts[2], parts[3], parts[4], parts[5])
}

export const getSeason = (date) => {
  const month = date.getMonth() + 1
  if ([12, 1, 2].includes(month)) return 'winter'
  if ([3, 4, 5].includes(month)) return 'spring'
  if ([6, 7, 8].includes(month)) return 'summer'
  return 'fall'
}
