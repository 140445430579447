import React from 'react'

interface UserContext {
  id: string | null
  email: string | null
}

const UserContext = React.createContext<UserContext>({
  id: null,
  email: null,
})

export default UserContext
