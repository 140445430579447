import React from 'react'
import PropTypes from 'prop-types'
import {Form, AutoComplete} from 'antd'

import './index.scss'

const DEFAULT_SEARCH_DELAY = 800
const GEO_REGION = 'no'

class LocationSearch extends React.Component {
  formRef = React.createRef()

  state = {
    autoCompleteSuggestions: [],
    searchTimer: null,
  }

  performSearch = (query) => {
    if (query.length >= 3) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({region: GEO_REGION, address: query}, (results, status) => {
        const autoCompleteSuggestions = status === 'OK' ? results : []
        this.setState({autoCompleteSuggestions})
      })
    }
  }

  searchUpdated = (query) => {
    clearTimeout(this.state.searchTimer)
    const delay = this.props.searchDelay !== undefined ? this.props.searchDelay : DEFAULT_SEARCH_DELAY
    const searchTimer = setTimeout(() => this.performSearch(query), delay)
    this.setState({searchTimer})
  }

  placeSelected = (placeId) => {
    const place = this.state.autoCompleteSuggestions.find(place => place.place_id === placeId)
    if (place) {
      const location = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
      this.props.locationSelected(location)
      this.setState({autoCompleteSuggestions: []})
      this.formRef.current.resetFields()
    }
  }

  render() {
    return (
      <div className="search-container">
        <Form ref={this.formRef} initialValues={{search: ''}}>
          <Form.Item noStyle name="search">
            <AutoComplete
              onSelect={this.placeSelected}
              onSearch={this.searchUpdated}
              placeholder="Søk og plasser markør..."
            >
              {this.state.autoCompleteSuggestions.map(place => (
                <AutoComplete.Option key={place.place_id} value={place.place_id}>
                  {place.formatted_address}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

LocationSearch.propTypes = {
  locationSelected: PropTypes.func.isRequired,
  searchDelay: PropTypes.number,
}

export default LocationSearch
