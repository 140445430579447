import React from 'react'
import {Link} from 'react-router-dom'
import {Card, Button} from 'antd'
import Query from 'components/shared/Query'
import PageContent from 'components/shared/PageContent'

import Users from './Users'

const Dashboard = () => {
  return (
    <div className="admin">
      <PageContent title="QA dashboard">
        <div className="queue-boxes">
          <Query path="photos/count" params={{by: 'all', status: 'pending'}}>
            {({data}) => (
              <Card title="Nye bilder til godkjenning" className="queue-box">
                <div className="content">
                  <div className="queue-count">{data.meta ? data.meta.total : ''}</div>
                  <Link to="/admin/qa/pending" className="link">
                    <Button type="primary">Til godkjenning</Button>
                  </Link>
                </div>
              </Card>
            )}
          </Query>

          <Query path="photos/count" params={{by: 'all', status: 'existing'}}>
            {({data}) => (
              <Card title="Eksisterende bilder til godkjenning" className="queue-box">
                <div className="content">
                  <div className="queue-count">{data.meta ? data.meta.total : ''}</div>
                  <Link to="/admin/qa/existing" className="link">
                    <Button type="primary">Til godkjenning</Button>
                  </Link>
                </div>
              </Card>
            )}
          </Query>
        </div>

        <div className="users-container">
          <Query
            inlineLoading
            path="users"
            params={{per_page: 5000}}
          >
            {({data}) => (
              <>
                <h2>Totaloversikt</h2>
                <Users users={data.users} />
              </>
            )}
          </Query>
        </div>

        <Link to="/admin/locations" className="link">
          <Button type="primary">Kart over bildelokasjoner</Button>
        </Link>
      </PageContent>
    </div>
  )
}

export default Dashboard
