import React from 'react'
import PropTypes from 'prop-types'
import {useLoadScript, GoogleMap, Marker} from '@react-google-maps/api'
import config from 'lib/config'
import './index.scss'

const DEFAULT_CENTER = {lat: 65.2, lng: 13.5}
const DEFAULT_ZOOM = 3

function PhotoLocationMap({location, locationChanged}) {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: config.GOOGLE_API_TOKEN,
  })
  if (loadError) {
    return (<div>Problem loading map!</div>)
  }
  if (!isLoaded) {
    return null
  }

  const zoom = location ? 12 : DEFAULT_ZOOM
  const center = location ? location : DEFAULT_CENTER

  const markerMoved = (evt) => {
    locationChanged({lat: evt.latLng.lat(), lng: evt.latLng.lng()})
  }

  return (
    <GoogleMap
      id="map-element"
      zoom={zoom}
      center={center}
      onClick={markerMoved}
    >
      {!!location && (
        <Marker
          draggable={true}
          position={location}
          onDragEnd={markerMoved}
        />
      )}
    </GoogleMap>
  )
}

PhotoLocationMap.propTypes = {
  location: PropTypes.object,
  locationChanged: PropTypes.func.isRequired,
}

export default PhotoLocationMap
