import React, {useState} from 'react'
import Query from 'components/shared/Query'
import PhotosWithGallery from 'components/shared/PhotosWithGallery'
import PageContent from 'components/shared/PageContent'
import LocationsMap from './LocationsMap'

import './index.scss'

const Locations = () => {
  const [page, setPage] = useState(1)
  const [currentBounds, setCurrentBounds] = useState(null)
  const [currentPhotoId, setCurrentPhotoId] = useState(null)

  const onClickCluster = (cluster) => {
    const b = cluster.getBounds().toJSON()
    setPage(1)
    setCurrentPhotoId(null)
    setCurrentBounds([b.west, b.south, b.east, b.north].join(','))
  }

  const onClickLocation = (location) => {
    setPage(1)
    setCurrentBounds(null)
    setCurrentPhotoId(location.locatable_id)
  }

  const params = {
    by: 'all',
    status: 'purchaseable',
  }

  if (currentPhotoId) {
    params['ids[]'] = currentPhotoId
  } else if (currentBounds) {
    params.within = currentBounds
  }

  return (
    <div className="admin">
      <PageContent title="Lokasjoner">
        <div className="locations-container">
          <div className="images-list">
            <PhotosWithGallery
              page={page}
              setPage={p => setPage(p)}
              perPage={6}
              params={params}
            />
          </div>

          <div className="images-map">
            <Query path="locations" params={{status: 'purchaseable'}}>
              {({data}) => (
                <LocationsMap
                  locations={data.locations ? data.locations : []}
                  onClickCluster={onClickCluster}
                  onClickMarker={onClickLocation}
                />
              )}
            </Query>
          </div>
        </div>
      </PageContent>
    </div>
  )
}

export default Locations
