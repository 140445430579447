import exif from 'exif-js'
import moment from 'moment'
import sizeOf from 'image-size'

import {parseDate, getSeason} from 'lib/dateUtil'
import config from 'lib/config'

export const parseCoordinate = (coord) =>
  coord[0].numerator +
  coord[1].numerator / (60 * coord[1].denominator) +
  coord[2].numerator / (3600 * coord[2].denominator)

export const parseImageMeta = (image) => {
  return new Promise((resolve) => {
    exif.getData(image, () => {
      let date = exif.getTag(image, 'DateTimeOriginal')
      if (date) date = parseDate(date)
      if (!date) date = new Date(image.lastModified)

      let gpsLocation = null
      let lat = exif.getTag(image, 'GPSLatitude')
      let lng = exif.getTag(image, 'GPSLongitude')
      if (lat && lng) {
        gpsLocation = {
          lat: parseCoordinate(lat),
          lng: parseCoordinate(lng)
        }
      }

      const {name, type, uid, size} = image
      resolve({
        date,
        gpsLocation,
        name,
        type,
        uid,
        fileSize: size,
      })
    })
  })
}

const getImageDimensions = (image) => {
  const buffer = new Buffer.from(image.split(',')[1], 'base64')
  const {width, height} = sizeOf(buffer)
  return {width, height}
}

export const getImageData = (image) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const imageData = reader.result

      const {width, height} = getImageDimensions(imageData)

      resolve({
        imageData,
        width,
        height,
      })
    }
    reader.readAsDataURL(image)
  })
}

export const getOrientation = (image) => {
  if (image.width > image.height) return 'Liggende format'
  else if (image.width < image.height) return 'Stående format'
  return 'Kvadratisk format'
}

export const formatFileSize = (bytes) => {
  if (bytes <= 0) return 'n/a'
  const units = ['b', 'KB', 'MB', 'GB', 'TB']
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${(bytes / Math.pow(1024, i)).toFixed(1)}${units[i]}`
}

export const createImage = (meta) => {
  const {name, type, date, gpsLocation, width, height, fileSize} = meta

  const image = {
    meta,
    name,
    type,
    taken_on: moment(date).format(config.IMAGE_DATE_FORMAT),
    season: getSeason(date),
    width,
    height,
    file_size: fileSize,
    tag_list: [],
    description: null,
    latitude: gpsLocation ? gpsLocation.lat : null,
    longitude: gpsLocation ? gpsLocation.lng : null,
    url_backend_storage: '',
    id: null,
  }

  return image
}
